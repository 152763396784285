#AutocountRouteComponentStyleContainer {
  // background-color: aliceblue;
  min-height: 709px;
}

.route__component {
  padding-top: 0px;
  // background-color: "aliceblue";
  // position: "relative",
  // padding-bottom: 50px;
  height: 100%;
  z-index: -10;
}
