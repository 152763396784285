@import '../../theme/common.scss';

.navigation {
  // flex: 0 0 60%;
  nav {
    display: flex;
    justify-content: space-between;
  }
  width: 50%;
}

.nav-menu-item {
  text-decoration: none;
  color: $experian-dark-blue;
  &:hover {
    color: $experian-magenta !important;
  }
}

.nav-menu-item--active {
  color: $experian-Raspberry !important;
}
