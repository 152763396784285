@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100&display=swap');
@import './common.scss';

header {
  flex-direction: column;
  align-items: center;
  -moz-transition: -moz-transform 0.325s ease-in-out, -moz-filter 0.325s ease-in-out,
    opacity 0.325s ease-in-out;
  -webkit-transition: -webkit-transform 0.325s ease-in-out, -webkit-filter 0.325s ease-in-out,
    opacity 0.325s ease-in-out;
  -ms-transition: -ms-transform 0.325s ease-in-out, -ms-filter 0.325s ease-in-out,
    opacity 0.325s ease-in-out;
  transition: transform 0.325s ease-in-out, filter 0.325s ease-in-out, opacity 0.325s ease-in-out;
  position: sticky;
  width: -webkit-fill-available;
  text-align: right;
  float: right;
}

// nav {
//     float: right;
//     width: -webkit-fill-available;
//     position: fixed;
// }

// li {
//     display: inline;
//     padding-left: 20px;
//     padding-right: 20px;
//     font-family: roboto;
// }

a.aNav {
  color: #888888;
}

@media only screen and (min-width: 1140px) and (min-height: 840px) {
  div#loginBackground {
    -moz-transition: background-color 2.5s ease-in-out;
    -webkit-transition: background-color 2.5s ease-in-out;
    -ms-transition: background-color 2.5s ease-in-out;
    transition: background-color 2.5s ease-in-out;
    -moz-transition-delay: 0.75s;
    -webkit-transition-delay: 0.75s;
    -ms-transition-delay: 0.75s;
    transition-delay: 0.75s;
    background-size: 2000px;
    background-position: bottom;
    background-image: '/images/Banner.png';
    -moz-transform: scale(1);
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    position: relative;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-repeat: no-repeat;
  }
}

@media only screen and (max-height: 840px) {
  div#loginBackground {
    -moz-transition: background-color 2.5s ease-in-out;
    -webkit-transition: background-color 2.5s ease-in-out;
    -ms-transition: background-color 2.5s ease-in-out;
    transition: background-color 2.5s ease-in-out;
    -moz-transition-delay: 0.75s;
    -webkit-transition-delay: 0.75s;
    -ms-transition-delay: 0.75s;
    transition-delay: 0.75s;
    background-size: 2000px;
    background-position: bottom;
    background-image: '/images/Banner.png';
    -moz-transform: scale(1);
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    position: relative;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-repeat: no-repeat;
  }
}

div#loginFormDiv {
  top: 13%;
  width: 100%;
  right: 5%;
  position: absolute;
  float: left;
}

#wrapper {
  background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
  display: block;
  height: 100vh;
  overflow: hidden;
}

//regular wrapper but faded
// @media only screen and (max-height: 840px) {
//     div#wrapper {
//         background-image: linear-gradient(to top, rgba(251, 251, 251, 00.67), rgba(251, 251, 251, 0.568));
//         height: 100%;
//         width: 100%;
//         position: absolute;
//         background-repeat: repeat;
//         -moz-transition: background-color 2.5s ease-in-out;
//         -webkit-transition: background-color 2.5s ease-in-out;
//         -ms-transition: background-color 2.5s ease-in-out;
//         transition: background-color 2.5s ease-in-out;
//     }
// }

div#wrapperFaded {
  background-image: linear-gradient(to top, rgba(251, 251, 251, 00.67), rgba(251, 251, 251, 0.568));
  height: 1000px;
  width: 100%;
  position: absolute;
  background-repeat: repeat;
  -moz-transition: background-color 2.5s ease-in-out;
  -webkit-transition: background-color 2.5s ease-in-out;
  -ms-transition: background-color 2.5s ease-in-out;
  transition: background-color 2.5s ease-in-out;
}

// form {
//     float: right;
//     background-color: #dddddd00;
//     box-shadow: 0 0 0 0;
//     width: 20%;
//     margin-right: 10%;
//     top: 3%;
//     right: 0;
//     position: absolute;
//     text-align: center;

// }

div#dampnerOn {
  float: right;
  background-color: #f1efefed;
  box-shadow: 0 0 0 0;
  width: 100%;
  margin-right: 10%;
  top: 3%;
  right: 0;
  position: absolute;
}
div#dampnerOff {
  float: right;
  background-color: #dddddd00;
  box-shadow: 0 0 0 0;
  width: 100%;
  margin-right: 10%;
  top: 3%;
  right: 0;
  position: absolute;
}

//height is greater than 800
h1#landingHeader {
  font-family: 'Open Sans', sans-serif;
  font-size: 100px;
  margin-left: 60px;
  position: sticky;
  width: 300%;
  top: 13%;
  display: inline-block;
  color: #575755;
}

h1#productsheader {
  font-family: 'Open Sans', sans-serif;
  font-size: 100px;
  margin-left: 60px;
  position: sticky;
  width: 300%;
  top: 13%;
  display: inline-block;
  color: #575755;
}

@media only screen and (max-height: 840px) {
  h1#landingHeader {
    font-family: 'Open Sans', sans-serif;
    font-size: 100px;
    margin-left: 60px;
    position: sticky;
    width: 300%;
    top: 13%;
    display: inline-block;
    color: white;
  }
}

//height is less than 800

//height is greater than 800
div#landingTitle {
  display: flex;
  position: absolute;
  top: 17%;
}

div#productsTitle {
  display: flex;
  position: absolute;
  top: 10%;
}

//height is less than 800
@media only screen and (max-height: 840px) {
  div#landingTitle {
    display: flex;
    position: absolute;
    top: 40%;
  }
}

img#landingTrademark {
  width: 40%;
  height: 40%;
  float: right;
  margin-left: 30px;
}

h2#loginHeader {
  font-family: 'Open Sans', sans-serif;
  text-align: center;
  color: #575755;
  padding-bottom: 10px;
}

a {
  // color:  #406eb3 ;
  display: block;
  text-align: center;
  margin-top: 10px;
}

a:hover {
  text-decoration: underline;
}

div#headerDisplay {
  color: #575755;
  font-family: 'Open Sans', sans-serif;
}

h2 {
  font-family: 'Open Sans', sans-serif;
  width: 100%;
}

h2.welcomeBanner {
  color: $experian-dark-blue;
  font-size: 15pt;
  font-weight: 400;
  margin: 1rem 1rem 1rem 0;
}

div#App {
  height: 100%;
}

//WIDGET SECTION

div#okta-sign-in.auth-container.main-container {
  margin: 0px auto 0px;
}

#okta-sign-in img.auth-org-logo {
  max-height: 300px;
}

div#okta-sign-in.auth-container.main-container {
  box-shadow: 5px 15px 20px 5px rgba(0, 0, 0, 0.4);
}

input#okta-signin-submit.button.button-primary {
  background: #ae3e92;
  background-color: #ae3e92;
  border-color: #ae3e92 #ae3e92 #ae3e92;
  border-color: #ae3e92e5 #ae3e92e5 #ae3e92e5;
}

input#okta-signin-submit.button.button-primary:hover {
  background: #ae3e92;
  background-color: #ae3e92;
  border-color: #ae3e92 #ae3e92 #ae3e92;
  border-color: #ae3e92e5 #ae3e92e5 #ae3e92e5;
}

input#okta-signin-submit.button.button-primary:focus {
  background: #ae3e92;
  background-color: #ae3e92;
  border-color: #ae3e92 #ae3e92 #ae3e92;
  border-color: #ae3e92e5 #ae3e92e5 #ae3e92e5;
}

li.resetPassword:hover {
  background-color: transparent;
}

// Query Multi Select

div.queryMultiSelectbox {
  border: 1px solid #000;
  top: 100px;
  width: 100%;
  height: 275px;
  box-sizing: border-box;
  border-color: #ccc6c6;
  bottom: 0;
}

h1#queryMultiSelectbox {
  padding-bottom: 15px;
  margin-bottom: 0px;
  background-color: white;
  margin-left: 5px;
}

div h1#queryMultiSelectbox .queryMultiSelectbox {
  text-align: center;
  margin-top: -10px;
  height: 20px;
  line-height: 20px;
  font-size: 15px;
  position: absolute;
  top: -0%;
}

div h1 span {
  background-color: white;
}

//Query Build Selections

div#fullQueryDisplay {
  position: absolute;
  top: 5%;
  left: 5%;
  width: 80%;
  height: 100vh;
  background-color: #f6f6fb;
  justify-content: space-between;
}

div#QueryBuildCardDisplay {
  display: flex;
  width: 100%;
  height: 30%;
  position: relative;
  float: left;
  flex-wrap: wrap;
}

div#territorySelection {
  background-image: '/images/territory.PNG';
  width: 100%;
  height: 100%;
}

div#territorySelectLayer {
  background-color: rgba(224, 222, 165, 0.7);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

div#indicatorSelection {
  background-image: '/images/indicator.PNG';
  width: 100%;
  height: 100%;
}

div#indicatorSelectionLayer {
  background-color: rgba(146, 239, 220, 0.7);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

div#dealerAndLenderSelection {
  background-image: '/images/dealersAndLenders.PNG';
  width: 100%;
  height: 100%;
}

div#dealerAndLenderSelectionLayer {
  background-color: rgba(219, 116, 228, 0.7);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

div#vehiclesSelection {
  background-image: '/images/vehicle.PNG';
  width: 100%;
  height: 100%;
}

div#vehiclesSelectionLayer {
  background-color: rgba(165, 182, 224, 0.7);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

//Scroll Bars

/* width */
// ::-webkit-scrollbar {
//     width: 10px;
//   }

//   /* Track */
//   ::-webkit-scrollbar-track {
//     box-shadow: inset 0 0 5px rgb(228, 223, 223);
//     border-radius: 5px;
//   }

//   /* Handle */
//   ::-webkit-scrollbar-thumb {
//     background: rgb(228, 223, 223);
//     border-radius: 5px;
//  END }

::-webkit-scrollbar {
  width: 16px;
  // border: 5px solid white;
}

::-webkit-scrollbar-thumb {
  background-color: #666464;
  background-clip: padding-box;
  border: 0.05em solid #eeeeee;
  width: 24px;
  border-radius: 10px;
}

::-webkit-scrollbar-track {
  background-color: #eeeeee;
}
/* Buttons */
::-webkit-scrollbar-button:single-button {
  background-color: #eeeeee;
  display: block;
  border-style: solid;
  height: 13px;
  width: 16px;
}
/* Up */
::-webkit-scrollbar-button:single-button:vertical:decrement {
  border-width: 0 8px 8px 8px;
  border-color: transparent transparent #bbbbbb transparent;
}

::-webkit-scrollbar-button:single-button:vertical:decrement:hover {
  border-color: transparent transparent #bbbbbb transparent;
}

/* Down */
::-webkit-scrollbar-button:single-button:vertical:increment {
  border-width: 8px 8px 0 8px;
  border-color: #bebaba transparent #bbbbbb transparent;
}

::-webkit-scrollbar-button:vertical:single-button:increment:hover {
  border-color: transparent transparent #bbbbbb transparent;
}

//buttons on querybuilder
#queryBuilderButton {
  outline: none !important;
  background-color: none;
}

//Single Select

#singleSelect-label {
  margin-top: 6px;
}

#AllButton:focus {
  outline: none;
}

//Accordian Styling
.MuiAccordionSummary-root.Mui-expanded {
  min-height: 20px !important;
  height: 25px;
}

.MuiAccordionSummary-root {
  min-height: 20px !important;
  height: 25px;
  padding-left: 2px !important;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(228, 223, 223);
  border-radius: 5px;
}


.css-1sd0hwy-MuiInputBase-root-MuiOutlinedInput-root
{
  border: #c4c4c4 1px solid !important;
  border-radius: 16px;
  overflow: hidden;
 }
 .css-1sd0hwy-MuiInputBase-root-MuiOutlinedInput-root:hover
 {
   border: black 1px solid !important;
   border-radius: 16px;
   overflow: hidden;
  }
 .css-1sd0hwy-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused {
   border: #426db1 2px solid !important;
   border-radius: 16px;
   overflow: hidden;
  }
