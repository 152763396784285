.dmaSelectDivStyle {
  width: 100%;
  left: 0%;
  position: 'relative';
}

.multiSelectDmaBottomStyle {
  width: 100%;
  left: 0%;
  position: 'relative';
  bottom: 0;
}

.territory__paper {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 50%;
  justify-content: space-between;
  padding: 30px;
  width: 100%;
}

.territory__mainContainer {
  display: 'flex';
  flex-direction: 'row';
  justify-content: 'space-between';
}

.territory__countyContainer {
  display: 'flex';
  flex-direction: 'column';
  // width: 400px;
}

.territory__title {
  margin-top: 10px;
  font-family: 300;
  // fontSize: 12px;
  text-align: 'right';
}

.territory__zipContainer {
  display: 'flex';
  flex-direction: 'column';
  // width: 400px;
  justify-content: 'space-between';
}

.territory__buttonContainer {
  margin-top: 28px;
  margin-left: 60px;
}

.territory__radioGroup {
  flex-direction: row !important;
}

.dmaSelection__multiSelect {
  width: 100%;
}
