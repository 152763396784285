.form__mainContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  // justify-content: start;
  // margin-top: 0px;
  background-color: #eeeeee;
}

.form__rowContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  // justify-content: start;
  margin-top: 15px;
}

.frequency__container {
  display: flex;
  flex-direction: column !important;
  border: 1px solid;
  // width: 400px;
}

.scheduling__columnGroup {
  display: flex;

  flex-direction: column !important;
}
.format_textWrapper {
  display: flex;

  flex-direction: row !important;
}

.grid_wrapper {
  margin: 15px;
}

.email_dropdown {
  min-width: 500px;
  max-width: 500px;
}

.schedule_buttonsWrapper {
  display: flex;
  flex-direction: column !important;
  justify-content: center;
}

.schedule_buttons {
  margin: 15px;
  width: 180px;
}

.email_wrapper {
  margin: 15px;
  width: 240px;
}

.addEmail_buttonWrapper {
  padding-top: 35px;
}

.format_message {
  align-self: center;
  padding: 15px;
}

.combined_files_title {
  margin-top: 1rem;
}