@import '../../theme/common.scss';
.errorPaper {
  border-radius: 6;
  padding: 2rem;
  height: fit-content;
  width: fit-content;
  svg {
    color: $experian-red;
    width: 1.5em;
    height: 1.5em;
  }
}
