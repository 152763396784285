.multiSelect {
  width: 100%;
}

.reportingPeriodFieldset {
  legend {
    width: auto;
    margin-bottom: 0;
    font-size: 12px;
    font-weight: bold;
    color: #1f497d;
    margin-left: 5px;
  }

  h5 {
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    font-size: 15px;
    padding-left: 5px;
    color: black;
  }
}
