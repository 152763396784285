.notFound__wrapper {
  background-image: linear-gradient(to top, rgba(251, 251, 251, 0.8), rgba(251, 251, 251, 0.568));
  height: 100vh;
  width: 100vw;
  background-repeat: repeat;
  transition: background-color 2.5s ease-in-out;
  position: relative;
}

.notFound__content {
  position: absolute;
  margin: 8%;

  .notFound__contentHeader {
    margin-bottom: 50px;
  }

  h1 {
    font-family: roboto;
    font-size: 65px;
    line-height: 70px;
    color: #575755;
    width: 100%;
  }

  h4 {
    font-family: roboto;
    font-size: 25px;
    line-height: 30px;
    color: #575755;
    width: 100%;
  }
}

.notFound__landingTrademark {
  width: 40%;
  height: 40%;
  float: right;
  margin-left: 30px;
}

.notFound__background {
  transition: background-color 2.5s ease-in-out;
  transition-delay: 0.75s;
  background-size: 2000px;
  background-position: bottom;
  background-image: '/images/Banner.png';
  -webkit-transform: scale(1);
  transform: scale(1);
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-repeat: no-repeat;
  position: absolute;
}

.notFound__button {
  background-color: #ae3e92 !important;
  color: white !important;
  height: 40px !important;
  border-radius: 6;
  padding-left: 40px !important;
  padding-right: 40px !important;
}
