@import '../../theme/common.scss';
.loginPanel {
  padding: 1rem;
  align-items: center;
  justify-content: center;
  h1 {
    font-size: 30pt;
    font-weight: 300;
    width: unset;
  }
  h2 {
    color: $experian-dark-blue;
    font-size: 15pt;
    font-weight: 400;
    width: unset;
    margin-top: 2rem;
    margin-bottom: 1rem;
  }
}
