.riskControlsWrapper {
  display: flex;
  flex-direction: column !important;
  margin: 5px;
}

.riskControl {
  display: flex;
  flex-direction: column;
  border: 1px solid;
  margin: 5px;
}

.riskControlLabel {
  margin: 15px;
}

.dynamicRiskControlWrapper {
  display: flex;
  flex-direction: row;
}

.dynamicRiskControl {
  display: flex;
  flex-direction: column;
  margin: 25px;

  margin-left: 40px;
}