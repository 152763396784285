/* Custom styles for SavedFilesTable */

 
  /* Style for the action buttons */
  .action-button {
    border: none;
    background: none;
    padding: 0;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  


  