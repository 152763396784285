$font-stack: 'Roboto', sans-serif;
$primary-color: #fff;

/* experian colors */

$experian-dark-blue: #1d4f91;
$experian-dark-blue-80: #4a72a7;
$experian-dark-blue-60: #7795bd;
$experian-dark-blue-40: #a5b9d3;
$experian-dark-blue-20: #d2dce9;
$experian-dark-blue-10: #e8edf4;
$experian-dark-blue-5: #f4f6fa;

$experian-light-blue: #426da9;
$experian-light-blue-80: #688aba;
$experian-light-blue-60: #8ea7cb;
$experian-light-blue-40: #b3c5dd;
$experian-light-blue-20: #d9e2ee;
$experian-light-blue-10: #ecf0f6;
$experian-light-blue-5: #f6f8fb;

$experian-purple: #6d2077;
$experian-purple-80: #8a4d92;
$experian-purple-60: #a779ad;
$experian-purple-40: #c5a6c9;
$experian-purple-20: #e2d2e4;
$experian-purple-10: #f0e9f1;
$experian-purple-5: #f8f4f8;

$experian-Raspberry: #af1685;
$experian-Raspberry-80: #bf459d;
$experian-Raspberry-60: #cf73b6;
$experian-Raspberry-40: #dfa2ce;
$experian-Raspberry-20: #efd0e7;
$experian-Raspberry-10: #f7e8f3;
$experian-Raspberry-5: #fbf3f9;

/*Experian Magenta*/
$experian-magenta: #e63888;
$experian-magenta-80: #eb60a0;
$experian-magenta-60: #f088b8;
$experian-magenta-40: #f5afcf;
$experian-magenta-20: #fad7e7;
$experian-magenta-10: #fdebf3;
$experian-magenta-5: #fef5f9;

$experian-pink: #ba2f7d; // OLD
$experian-blue: #26478d; // OLD?

$experian-violet: #632678;
/*tertiary */

$experian-red: #bb0048;
$experian-orange: #e2a235;
$experian-yellow: #fde723;
$experian-green: #c8c922;
$experian-sapphire: #3d87af;

/*grays*/

$dark-grey: #333333;
$medium-grey: #e1e1e1;
$medium-dark-grey: #777777;
$light-grey: #f4f4f4;
$very-light-grey: #f9fafb;

/*light */

$very-light-blue: #eaeff5;
$very-light-pink: #f9ebf2;
$very-light-purple: #f1eaf2;

/*common */

$removeBootsrapPadding: 0;
$mainAppMargin: 0px auto;

/* color classes */

.red {
  background: $experian-red;
}

.orange {
  background: $experian-orange;
}

.yellow {
  background: $experian-yellow;
}

.green {
  background: $experian-green;
}

.sapphire {
  background: $experian-sapphire;
}

.light-blue {
  background: $experian-light-blue;
}

.dark-blue {
  background: $experian-dark-blue;
}

.magenta {
  background: $experian-magenta;
}

.purple {
  background: $experian-purple;
}

.violet {
  background: $experian-Raspberry;
}

#root {
  min-height: 100vh;
}

/*mix-ins*/

@mixin overflow() {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  -ms-border-radius: $radius;
  border-radius: $radius;
}

@mixin box-shadow($box-shadow) {
  -webkit-box-shadow: $box-shadow;
  -moz-box-shadow: $box-shadow;
  box-shadow: $box-shadow;
}

@mixin href-hover() {
  cursor: pointer;
  text-decoration: none;
}

@mixin border-width($border-width, $border-style, $border-color) {
  border-top: $border-width $border-style $border-color;
  border-left: $border-width $border-style $border-color;
  border-bottom: $border-width $border-style $border-color;
  border-right: $border-width $border-style $border-color;
}
