.contactUs__title {
  color: white;
  width: auto;
  padding-right: 0px;
}

.contactUs__submitText {
  width: 900px;
}

.contactUs__mainFlex {
  position: relative;
  display: flex;
  justify-content: center;
}

.contactUs__align {
  text-align: center;
}

$experian-purple: #72217b;
$experian-blue: #1d4f91;

.contactUs__buttonEnabled {
  background-color: $experian-purple !important;
  color: white !important;
  text-transform: none !important;
}

.contactUS__buttonLowerCase {
  text-transform: none !important;
}

.contactUs__redMessage {
  color: red;
}

.contactUs__greenMessage {
  color: green;
}

.contactUs__cardContainer {
  min-width: 345px;
  margin-left: 25px;
  margin-right: 25px;
}

.contactUs__cardMediaContainer {
  position: relative;
  display: flex !important;
  justify-content: center;
  align-items: center;
  width: 1024px;
  height: 184px;
}

.contactUs__subtitleJustification {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contactUs__subtitle {
  padding-top: 25px;
  text-align: center;
  color: black;
}

.contactUs__Paper {
  width: 1024px;
  min-height: 100px;
  background-color: #f1efefdc !important;
}

.contactUs__SubmitDiv {
  padding-top: 25px;
  padding-bottom: 25px;
}

.contactUs__button {
  color: white !important;
  width: 200px !important;
  padding-top: 13px !important;
  padding-bottom: 13px !important;
  background-color: $experian-blue !important;
  text-transform: none !important;
}

.contactUs__buttonHashLink {
  color: white !important;
  width: 200px !important;
  height: 60px !important;
  padding-top: 13px !important;
  padding-bottom: 13px !important;
  background-color: $experian-blue !important;
  text-transform: none !important;
}

.contactUs__HashLinkCentering {
  padding-right: 30px;
}

.contactUs_HashLinkText {
  white-space: pre-wrap;
}

.contactUs__HashLinkButtonSpace {
  margin-bottom: 20px;
}

.contactUs__completeFormLabel {
  color: rgba(0, 0, 0, 0.6);
}
