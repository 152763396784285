.riskOptions {
  h4 {
    margin-bottom: 0;
  }
  #newSavedOptionNameTextField {
    margin-bottom: 2px;
  }
  .checkbox {
    margin-top: 6px;
  }
  .distributionBand {
    margin-left: 2px;
  }
  .selectorWithASpace {
    margin-left: 2px;
  }
}
