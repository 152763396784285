.advancedQuery {
  position: relative;
}

.advancedQuery__buttons {
  // position: absolute;
  // top: 40px;
  right: 20px;
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
  z-index: 10;
  background: #ffffff;
  border-bottom: 2px solid #eeeeee;

  display: flex;
  flex-direction: row;
  justify-content: center;
}

.advancedQuery__buttons button {
  margin: 10px;
}
.advancedQuery__title {
  // background: aliceblue;
  margin-left: 16px;
  // margin-top: 30px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 300;
  font-size: 24px;
}

.advancedQuery__paper {
  padding-top: 0px;
}

.advancedQuery__resetButton {
  background-color: #982881 !important;
  height: 60px;
  color: white !important;
  border-radius: 6;
  padding-left: 40px !important;
  padding-right: 40px !important;
  &:hover {
    background-color: darken(#982881, 10%) !important;
  }
}
