.oktaContainer {
  #okta-sign-in {
    margin: unset;
    border-style: unset;
    box-shadow: unset !important;
    .okta-sign-in-header {
      padding: unset;
    }
    .beacon-container {
      position: absolute;
      bottom: -60px !important;
      right: 0;
      left: 0;
      margin: auto;
      width: 44px !important;
      height: 44px !important;
      -webkit-transform: scale(0);
      transform: scale(0);
    }
  }
}
