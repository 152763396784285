.help-tip {
  position: absolute;
  top: 18px;
  right: 18px;
  text-align: center;
  background-color: #bcdbea;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  font-size: 14px;
  line-height: 26px;
  cursor: default;
}

.help-tip:before {
  content: '?';
  font-weight: bold;
  color: #fff;
}

.help-tip:hover p {
  display: block;
  transform-origin: 100% 0%;

  -webkit-animation: fadeIn 0.3s ease-in-out;
  animation: fadeIn 0.3s ease-in-out;
}

.help-tip p {
  /* The tooltip */
  display: none;
  text-align: left;
  background-color: #1e2021;
  padding: 20px;
  width: 300px;
  position: absolute;
  border-radius: 3px;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
  right: -4px;
  color: #fff;
  font-size: 13px;
  line-height: 1.4;
}

.help-tip p:before {
  /* The pointer of the tooltip */
  position: absolute;
  content: '';
  width: 0;
  height: 0;
  border: 6px solid transparent;
  border-bottom-color: #1e2021;
  right: 10px;
  top: -12px;
}

.help-tip p:after {
  /* Prevents the tooltip from being hidden */
  width: 100%;
  height: 40px;
  content: '';
  position: absolute;
  top: -40px;
  left: 0;
}

/* CSS animation */

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
    transform: scale(0.6);
  }

  100% {
    opacity: 100%;
    transform: scale(1);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 100%;
  }
}

.titleSlection_container {
  display: flex;
  flex-direction: 'row';
  justify-content: space-between;
  width: 100%;
}

.titleSlection_columnContainer {
  flex-direction: 'column';
  width: 800px;

  margin-top: 10px;
  padding-right: 50px;
}

.titleSlection_buttonsContainer {
  display: flex;
  flex-direction: 'column';
  justify-content: space-evenly;
  // width:100%;
}

.titleSlection_labelContainer {
  // padding-top: 250px;
  text-align: center;
}

.advancedQuery_stateContainer {
  display: flex;
}

.advancedQuery__allStatesButton {
  background-color: #982881 !important;
  height: 54px;
  color: white !important;
  border-radius: 6;
  padding-left: 40px !important;
  padding-right: 40px !important;
  &:hover {
    background-color: darken(#982881, 10%) !important;
  }
}

.advancedQuery_stateContainer > .multiSelect {
  max-width: 76%;
}

.trendReport__dropdownWrapper {
  display: flex;
  justify-content: space-between;
  margin: 10px 0 10px 0;
}

.trendReport__dropdownWrapper > div {
  width: 49%;
}

.stateRadioButtons {
  width: 1.6rem !important;
}

.TitleSelection__CapitilizeOptions span {
  font-weight: 600;
  font-size: 15px;
}