.text-loader__container {
  display: flex;
}

.text-loader__details {
  display: flex;
  flex-direction: column;
}
.text-loader__content {
  flex: 1 0 auto;
}
.text-loader__cover {
  height: 210px;
  width: 350px;
}
