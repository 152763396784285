.collapsable {
  margin-bottom: 15px;
  // width: 90%;
  // margin-left: 5%;
  display: flex;
  flex-direction: column;
  border-style: solid;
  border-color: #eeeeee;
  // border-color: #e5e5e5
}

.collapsable__accordionSummary {
  background-color: #eeeeee !important;
  height: 40px;
}

.collapsable__accordionDetails {
  width: 100%;
  padding-left: 6px;
  display: flex;
  flex-direction: column;
}

.collapsable__accordionSummaryTitle {
  width: 100%;
  color: #333333;
  margin-left: 12px;

  h4 {
    font-weight: 600;
    font-size: 22px;
  }
}

.collapsable__accordionDetailsTitle {
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  font-size: 17px;
}

.collapsable__accordionDetailsContent {
  // padding: 15px;
}

.collapsable__disabled {
  border-color: #959595 !important;
  box-shadow: none !important;
}

.MuiAccordionSummary-root.Mui-expanded {
  height: 40px;
}
