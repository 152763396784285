@import '../../theme/common.scss';

$animate: all 0.05s ease-in-out;

.tabsNavigator__container {
  margin: -79px -50px 10px -50px;
  padding: 10px 50px 0px 50px;
  // background: #71207c;
  // left: -100px;
  // position: relative;
  // width: 500%;
  // margin-left: -200%;
}
.tabsNavigator__banner {
  margin: 0px -50px 25px 0px;
  padding: 40px 50px 0px 50px;
  background: #71207c;
  position: absolute;
  width: 100%;
  // margin-left: -100%;
  // width: 100%;
}
.tabsNavigator__inner,
.tabsNavigator__navLeft {
  display: flex;
  align-items: center;
}

.tabsNavigator__navRight > .ui_button--pink {
  z-index: 1;
  margin-top: -5px;
}

.tabsNavigator__inner {
  justify-content: space-between;
}

.tabsNavigator__innerNavItem {
  .hover {
    font-size: 12pt;
    text-align: center;
    margin: 0 auto;
    padding: 5px 35px;
    color: #ffff;
    transition: $animate;
    text-align: center;

    position: relative;
    &:before,
    &:after {
      content: '';
      position: absolute;
      bottom: -10px;
      width: 0;
      height: 3px;
      margin: 3px 0 0;
      transition: $animate;
      transition-duration: 0.15s;
      opacity: 0;
      background-color: $experian-magenta;
    }
    &.hover-3 {
      &:before {
        left: 50%;
      }
      &:after {
        right: 50%;
      }
    }
  }
  &:hover {
    cursor: pointer;
    .hover {
      &:before,
      &:after {
        width: 100%;
        opacity: 1;
      }
      &.hover-3 {
        &:before,
        &:after {
          width: 50%;
        }
      }
    }
  }
}

.tabsNavigator__menuItemActive {
  font-weight: bold;
  background-color: white;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  color: #71207c !important;
  // box-shadow: 5px 4px aliceblue;
  // &:before,
  // &:after {
  //   content: "";
  //   position: absolute;
  //   bottom: -10px;
  //   height: 3px;
  //   margin: 3px 0 0;
  //   transition: $animate;
  //   transition-duration: 0.75s;
  //   width: 50% !important;
  //   opacity: 1 !important;
  //   background-color: $experian-magenta;
  // }
}

@media only screen and (min-width: 1470px) {
  .tabsNavigator__innerNavItem {
    min-width: 265px;
  }
}
