.dealersAndLendersSelection__container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  > div {
    margin-right: 5px;
  }
}

.dealersAndLenderSelection__search {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 50%;
  > div {
    margin-right: 5px;
  }
  
}

.dealersAndLendersSelection__leftColumn {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  > div {
    margin-right: 5px;
  }
  
}

.dealersAndLendersSelection__rightColumn {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  > div {
    margin-right: 5px;
  }
}

.dealersAndLendersSelection__column {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  > div {
    margin-right: 5px;
  }
}

.dealersAndLenderSelection__row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  // align-items: center;
}

.dealersAndLendersSelection__wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 20px !important;
  padding-bottom: 20px !important;
  border-bottom-color: #e5e5e5;
  border-bottom-style: solid;
}

.dealersAndLendersSelection__leftButton {
  margin-top: 5px;
  float: right;
  // width: 30px;
}

.dealersAndLendersSelection__rightButton {
  margin-top: 5px;
  float: left;
}

.dealersAndLendersSelection__button {
  float: right;
  height: 56px;
  margin-bottom: 5px !important;
  width: 100px;
}

.dealersAndLendersSelection__papper {
  position: relative;
  height: 100%;
  padding: 10px 30px 0 30px;

  width: 100%;
}

.dealersAndLendersSelection__leanderContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.dealersAndLenderSelection__selectContainer {
  min-width: 145px;
}

.dealersAndLendersSelection__buttonContainer {
  margin-top: 28px;
  float: right;
}

.dealersAndLenderSelection__leftColumn {
  width: 48%;
}

.dealersAndLenderSelection__centerColumn {
  display: flex;
  flex-direction: column;
  margin: 30px 10px 0 10px;
  justify-content: center;
}

.dealersAndLenderSelection__rightColumn {
  width: 48%;
}
