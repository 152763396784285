@import '../../theme/common.scss';

.link {
  display: inline;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.43;
  letter-spacing: 0.01071em;
  color: #fff;
}

.snackBar {
  background-color: $experian-violet;
}

.overlay {
  position: flex;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  align-items: flex-end;
}

.acceptButton {
  color: white;
}
