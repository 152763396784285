.queryInfo {
  padding: 30px;
  h5 {
    font-size: 1.25rem;
    font-weight: '500';
    margin-bottom: 2rem;
  }
  .accordion {
    min-height: 35px;
    padding-left: 1rem;
    min-width: 50px;
  }
}
