@import '../../../../theme/common.scss';

.notification-title {
  border-bottom: 1px solid $light-grey;
}

.notification-sub-title {
  font-weight: 500 !important;
}

.notification-box {
  margin-top: 2rem;
  border-radius: 8px !important;
  * {
    color: $dark-grey !important;
  }
}

.notification-content {
  background-color: $very-light-grey;
  margin: 0rem 1rem 1rem;
  p {
    font-weight: 500;
  }
}
