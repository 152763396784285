.additional-info_container {
  background-color: rgb(240, 243, 250);
  max-height: 450px;
  max-width: 500px;
  overflow: auto;
}

.additional-info_button-container {
  display: flex;
  flex-direction: row;
}

@media only screen and (max-width: 1940px) {
  .additional-info_button-container {
    margin-left: 25px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
  }
}

.display-button {
  height: 2.5rem;
  margin-top: 0.5rem;
}
