.tabs_manager_wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.state_availabilities_container {
    height: 50vh;
    overflow-y: auto;
  }
