.tableau-vizualization__actions div {
  display: flex;
  justify-content: center;
}

.tableau-vizualization__button {
  margin: 10px !important;
  width: 100px;
}

.test:first-child {
  width: 1320 !important;
}
