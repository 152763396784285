.tableauManager__containerMain {
  display: flex;
  justify-content: center;
  flex-direction: row;
  overflow: auto;
}

#tableauContainer {
  width: 80%;
}
iframe {
  width: 100%;
}

.additional-info_button-container {
  margin-bottom: 10px;
}

@media only screen and (max-width: 1940px) {
  .tableauManager__containerMain {
    flex-direction: column !important;
  }
  #tableauContainer {
    width: 100%;
  }
}
