html {
  block-size: 600px;
}

body,
a {
  font-family: Roboto, sans-serif;
  color: #575755;
}

.nav-link {
  width: 250px;
}

.modal-dialog {
  transform: none;
  margin-top: 7%;
  display: flex;
  /* margin-left: calc(-180px + 50%); */
}

.divLeft {
  float: left;
  text-align: left;
}

.divRight {
  float: right;
  text-align: right;
}

.h4,
h4 {
  font-size: 0.87rem;
}

.modal-form-wrapper {
  margin-top: 5%;
  /* margin-left: 5%;
    margin-right: 5%; */
}

.txtUserNotes {
  min-width: 100%;
  min-height: 110px;
  border-radius: 6px;
  border: 1px solid #e2e2e1;
  font-size: 0.87rem;
}

textarea:focus {
  box-shadow: 0px 0px 0px 2px rgba(63, 81, 181, 0.25);
  border-color: #3f51b5;
  background-color: #fff;
}

.alignTextCenter {
  text-align: center !important;
}

.highlightText {
  color: #007bff;
}

.errorText {
  font-size: 10px;
  color: #f44336;
}

.noTreeView {
  max-width: 400;
  padding: 10px;
  margin-left: 5%;
}

input[type='checkbox'],
input[type='radio'] {
  box-sizing: border-box;
  margin: 30%;
  margin-top: 20%;
}

.rt-td {
  white-space: normal !important;
}

.noteColumnField {
  width: 100%;
}

.alignProgressSpinner {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 2%;
}

.modal-footer {
  background-color: #eee;
}

.experianLogoHeader {
  width: 161px;
  text-decoration: none;
  margin-top: 11px;
  margin-right: auto;
  margin-bottom: 12px;
  margin-left: auto;
  background-repeat: no-repeat;
  display: flex;
  white-space: pre;
  background-size: 161px 49px;
}

.sidebar {
  padding-top: 25px;
}

.divAlert {
  padding-bottom: 25px;
}

.MuiAutocomplete-noOptions {
  padding: 4px 16px !important;
  font-size: 0.87rem !important;
  color: #007bff !important;
}

#dvAMbuttons {
  margin-top: 15px;
}

.MuiTooltip-popper {
  font-size: 15px !important;
}

@media only screen and (max-width: 1000px) {
  .modal-content {
    width: fit-content;
    height: fit-content;
  }
  .makeStyles-drawerPaper-9 {
    max-width: 64px !important;
  }
  .modal-dialog {
    margin-left: calc(-230px + 50%);
  }
  .makeStyles-appBarShift-5 {
    width: 100% !important;
  }
}

.form-group-min-margin {
  margin-bottom: 1px;
}

/* Login page */

@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700');

div#form {
  float: right;
  background-color: #dddddd00;
  box-shadow: 0 0 0 0;
  width: 20%;
  margin-right: 10%;
  top: 3%;
  right: 0;
  position: absolute;
  text-align: center;
}
@media only screen and (max-height: 840px) {
  div#form {
    float: right;
    background-color: #ffffff;
    box-shadow: 0 0 0 0;
    width: 26%;
    margin-right: 10%;
    top: 30%;
    right: 0;
    position: absolute;
    text-align: center;
    padding: 1em;
    border-radius: 15px;
  }
}

div#form-error {
  float: right;
  background-color: #dddddd00;
  box-shadow: 0 0 0 0;
  width: 20%;
  margin-right: 10%;
  top: 3%;
  right: 0;
  position: absolute;
  text-align: center;
  border: 1px solid #000;
}

p#form-error {
  text-align: center;
  margin-top: -10px;
  height: 20px;
  line-height: 20px;
  font-size: 15px;
}

.card {
  border-radius: 3;
  button {
    height: 100%;
  }
}

.cardMedia {
  height: 120px;
}

.cardContent {
  h5 {
    font-size: 14pt;
  }
  p {
    font-size: 11pt;
  }
}

.card:hover {
  transform: scale(1.02) perspective(0px);
  box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 25%), 0px 4px 5px 0px rgb(0 0 0 / 19%),
    0px 1px 10px 0px rgb(0 0 0 / 17%);
}

.rightPanel {
  padding-top: 5rem;
  div {
    height: 100%;
    width: 100%;
  }
}

.logo {
  height: 50px;
  width: 150px;
  margin-bottom: 3rem;
}

.leftPanel {
  padding: 4rem;
  h1 {
    font-size: 30pt;
    font-weight: 300;
    width: 100%;
  }
  .errorPaper {
    margin-top: 7rem;
  }
}

.landingPageImage {
  display: flex;
  height: 100vh;
  width: 100%;
}

.formtext {
  position: relative;
  top: -48px;
  background: white;
  padding: 7px;
  width: 57px;
  color: red;
}

div.addProductTop {
  border-bottom: 2px solid rgb(219, 216, 216);
  height: 40px;
  width: 100%;
}
h6 {
  border-bottom: black;
  padding-left: 7px;
  padding-top: 5px;
}

div#addProductPad {
  width: 90%;
  height: 90%;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
}

div#tableborder {
  margin-right: 0px;
  box-shadow: 0 2px 0 rgba(90, 97, 105, 0.11), 0 4px 8px rgba(90, 97, 105, 0.12),
    0 10px 10px rgba(90, 97, 105, 0.06), 0 7px 70px rgba(90, 97, 105, 0.1);
  /* float: left; */
  background-color: white;
  width: 100%;
  border-top: 8px ridge #3d87af;
  border-bottom: 8px ridge #3d87af;
  border-left: 8px ridge #3d87af;
  border-right: 8px ridge #3d87af;
}

table {
  margin-bottom: 0em;
}

div#choiceBox {
  width: 700px;
  height: 300px;
  background-color: white;
}

/* ul,
li {
  margin: 0;
  padding: 0;
  list-style: none;
  width: 100%;
  display: inline-block;
} */

div.wrapper {
  display: inline;
}
div.dd-wrapper {
  width: 50%;
  float: left;
  height: 70%;
  margin-top: 20px;
}

div#addProduct {
  width: 30%;
  box-shadow: 0 2px 0 rgba(90, 97, 105, 0.11), 0 4px 8px rgba(90, 97, 105, 0.12),
    0 10px 10px rgba(90, 97, 105, 0.06), 0 7px 70px rgba(90, 97, 105, 0.1);
  /* float: left; */
  background-color: white;
  height: 400px;
  float: right;
  margin-right: 20px;
}

div#displayDiv {
  float: right;
  margin-left: 400px;
  padding-top: 50px;
  margin-right: 40px;
}

div#scrollBackground {
  background-color: white;
  width: 330px;
  height: 300px;
  margin-left: 10px;
  margin-top: 10px;
  overflow: auto;
  vertical-align: middle;
  display: inline-block;
  border-radius: 0.625rem 0.625rem 0.625rem 0.625rem;
  margin-top: 20px;
  margin-bottom: 20px;
}

li.productAssgin {
  box-shadow: 0 0px 0 rgba(90, 97, 105, 0.11), 0 4px 0px rgba(90, 97, 105, 0.12),
    0 0px 0px rgba(90, 97, 105, 0.06), 0 0px 0px rgba(90, 97, 105, 0.1);
  padding-top: 5px;
  padding-bottom: 2px;
}

li:hover {
  background-color: rgba(0, 0, 0, 0.08);
}

div#row1 {
  width: 60%;
  float: right;
  margin-right: 250px;
  margin-top: 150px;
  background-color: #a7a7aadb;
  text-align: center;
  -webkit-box-shadow: 11px 15px 34px -1px rgba(82, 82, 82, 1);
  -moz-box-shadow: 11px 15px 34px -1px rgba(82, 82, 82, 1);
  box-shadow: 11px 15px 34px -1px rgba(82, 82, 82, 1);
  border-top: 4px solid rgb(219, 216, 216);
  border-bottom: 4px solid rgb(219, 216, 216);
  border-left: 4px solid rgb(219, 216, 216);
  border-right: 4px solid rgb(219, 216, 216);
  height: fit-content;
  padding: 40px 40px 40px 40px;
}

div#col1 {
  float: right;
  padding-top: 10px;
  background-color: #3d87af;
  border-radius: 0.625rem 0.625rem 0.625rem 0.625rem;
  margin-left: 70px;
  margin-top: 5px;
}

div#col2 {
  float: left;
  width: 50%;
}

div#col3 {
  float: left;
  padding-top: 10px;
  display: inline-block;
  margin-left: 40px;
  background-color: #3d87af;
  border-radius: 0.625rem 0.625rem 0.625rem 0.625rem;
  margin-top: 5px;
}

div#col4 {
  float: left;
}

svg {
  float: right;
}

svg.doubleArrow {
  height: 50px;
  width: 50px;
  float: left;
}

h3 {
  float: left;
  width: 100%;
  padding-bottom: 10px;
  font-family: 'Open Sans', sans-serif;
  font-size: 1.5vw;
}

h3#left {
  box-shadow: 0 0px 0 rgba(90, 97, 105, 0.11), 0 4px 0px rgba(90, 97, 105, 0.12),
    0 0px 0px rgba(90, 97, 105, 0.06), 0 0px 0px rgba(90, 97, 105, 0.1);
  border-radius: 0.625rem 0.625rem 0.625rem 0.625rem;
  border-bottom-left-radius: 0.625rem;
  border-bottom-right-radius: 0.625rem;
  border-radius: 0.625rem 0.625rem 0 0;
  color: #fdfdf4;
  font-size: 1vw;
  font-family: 'Open Sans', sans-serif;
  background-color: #3d87af;
}

h3#right {
  box-shadow: 0 0px 0 rgba(90, 97, 105, 0.11), 0 4px 0px rgba(90, 97, 105, 0.12),
    0 0px 0px rgba(90, 97, 105, 0.06), 0 0px 0px rgba(90, 97, 105, 0.1);
  border-radius: 0.625rem 0.625rem 0 0;
  border-bottom-left-radius: 0.625rem;
  border-bottom-right-radius: 0.625rem;
  border-radius: 0.625rem 0.625rem 0 0;
  background-color: #3d87af;
  color: #575755;
  font-size: 1vw;
  color: #fdfdf4;
}

h3.fade-in {
  opacity: 0;
}
h3.fade-in.visible {
  transition: opacity 1s ease-in;
  opacity: 1;
  font-family: 'Open Sans', sans-serif;
}

div#row2 {
  width: 80%;
  float: right;
  margin-right: 50px;
  margin-top: 40px;
  background-color: #a7a7aadb;
  text-align: center;
  -webkit-box-shadow: 11px 15px 34px -1px rgba(82, 82, 82, 1);
  -moz-box-shadow: 11px 15px 34px -1px rgba(82, 82, 82, 1);
  box-shadow: 11px 15px 34px -1px rgba(82, 82, 82, 1);
  border-top: 4px solid rgb(219, 216, 216);
  border-bottom: 4px solid rgb(219, 216, 216);
  border-left: 4px solid rgb(219, 216, 216);
  border-right: 4px solid rgb(219, 216, 216);
  padding: 15px 15px 15px 15px;

  height: fit-content;
  /* padding: 10px 40px 100px 10px; */
}

h1 {
  font-family: 'Open Sans', sans-serif;
  /* border-bottom: 2px solid #131312; */
  padding-bottom: 5px;
  margin-bottom: 10px;
  width: 40%;
  position: center;
  text-align: left;
  /* vertical-align: middle; */

  /* border-top: 3px solid #131312; */
}

h1#prdouctsHeader {
  float: left;
}

div#productsHeader {
  background-color: #a7a7aa;
  height: 60px;
  width: auto;
}
div#row3 {
  width: 60%;
  float: right;
  margin-right: 250px;
  margin-top: 100px;
  background-color: #a7a7aa;
  text-align: center;
  -webkit-box-shadow: 11px 15px 34px -1px rgba(82, 82, 82, 1);
  -moz-box-shadow: 11px 15px 34px -1px rgba(82, 82, 82, 1);
  box-shadow: 11px 15px 34px -1px rgba(82, 82, 82, 1);
  border-top: 4px solid rgb(219, 216, 216);
  border-bottom: 4px solid rgb(219, 216, 216);
  border-left: 4px solid rgb(219, 216, 216);
  border-right: 4px solid rgb(219, 216, 216);
  padding: 40px 40px 40px 40px;
  margin-bottom: 70px;

  height: fit-content;
  /* padding: 10px 40px 100px 10px; */
}

div#border {
  width: 100%;
  height: 100%;
  background-color: rgb(15, 38, 92);
  /* color: darkblue; */
}

div#bottom {
  float: left;
  clear: both;
}

h4 {
  display: inline;
  padding-right: 15px;
}

.MuiButton-root {
  font-weight: 400 !important;
}
