.indicator__icon {
  width: 10%;
  height: 50%;
  color: rgba(146, 239, 220, 0.7);
}

.indicator__typeContainer {
  display: flex;
  flex-direction: row;
  padding-left: 10px;
}

.indicator__typeTitle {
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  font-size: 15px;
  margin-bottom: 10px;
}

.indicator__paper {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 50%;
  justify-content: space-between;
  padding: 10px 30px;
  width: 100%;
}

.indicator__title {
  width: 100%;
  text-align: left;
  padding-top: 20px;
}

.indicator__checkBox {
  float: right;
  z-index: 10;
  color: #26478d;
}

.indicatorMultiSelect {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.indicator__switch {
  float: right;
  z-index: 10;
}

.indicator__formControlLabel {
  background-color: #ecf1f7;
  border-radius: 18px;
  padding-bottom: 2px;
  margin-bottom: 10px;
  margin-left: 12px;
  padding-right: 30px;
}
