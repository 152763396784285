$experian-pink-ish: #af1685;
$experian-blue: #1d4f91;

.footer__gradient {
  //   margin-top: 100px;
  height: 7px;
  background-color: $experian-blue; /* For browsers that do not support gradients */
  background-image: linear-gradient(to right, $experian-pink-ish, $experian-blue);
  bottom: 0;
  width: 100%;
}

.footer__allRightsReserved {
  padding: 30px 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  // width: 100%;
  margin-left: 50px;
  margin-right: 50px;
}

.footer__allRightsReservedJustify {
  text-align: justify;
}

.footer__allRightsReservedSpace {
  padding-top: 20px;
}

.footer_wrapper {
  height: 200px;
}
