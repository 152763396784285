.SelectReport__mainContainer {
  display: 'flex';
  flex-direction: 'row';
  justify-content: 'space-between';
}

.SelectReport__countyContainer {
  display: 'flex';
  flex-direction: 'column';
  // width: 400px;
}
