.marquee__mainFlex {
  position: relative;
  display: flex;
  justify-content: center;
}

.marquee__cardContainer {
  min-width: 345px;
  margin-left: 25px;
  margin-right: 25px;
}

.marquee__cardMediaContainer {
  position: relative;
  display: flex !important;
  justify-content: center;
  align-items: center;
  width: 1024px;
  height: 184px;
}

.marquee__align {
  text-align: center;
}

.marquee__title {
  color: white;
  width: auto;
  padding-right: 0px;
}

$experian-purple: #72217b;
$experian-blue: #1d4f91;
