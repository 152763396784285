@import '../../theme/common.scss';

.header-wrapper {
  display: flex;

  padding: 5px 50px;
  align-items: center;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
  margin-bottom: 3px;
  justify-content: space-between;
}

.experian-logo {
  flex: 0 0 0%; //lauara had this set to 0 0 20%
  img {
    width: 140px;
  }
}

.autocount-logo {
  flex: 0 0 0%; //lauara had this set to 0 0 20%
  img {
    width: 220px;
  }
}

.navbar-wrapper {
  display: flex;
  padding: 5px 50px;
  align-items: center;
  justify-content: space-between;
}
