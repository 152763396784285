.infoContainer {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    .titleContainer {
        width: 30%;
    }
    .title {
        margin-top: 1rem;
      }
      .valueContainer {
        width: 70%;
        height: 2rem;
        display: flex;
      }
  }
