@import '../../theme/common.scss';

.navbar-wrapper {
  display: flex;
  padding: 5px 155px;
  position: relative;
  background-color: #eeeeee;
  border-bottom: 1px solid $light-grey;
  // align-items: center;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
  // margin-bottom: 3px;
  justify-content: space-between;
  z-index: 1;
}

// .navigation-tabs{
//   display: flex;
//   flex-direction: row;
//   justify-content: flex-start;
// }

.navbar-container {
  display: flex !important;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 0px;
  padding-right: 0px;
}

.root {
  display: flex;
  position: relative;
  width: 20%;
  justify-content: flex-end;
}

.persona_container {
  display: flex;
  margin-right: 40px;
  width: 20%;
  justify-content: flex-end;
}
